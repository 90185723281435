
.error{
    font-family: 'Poppins',sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 128px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #05174B;
    }
    
    .notfound{
    font-family: 'Poppins',sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #05174B;
    }
    
    .txt
    {
    font-family: 'Poppins',sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #000000;
    }
    
    
    .btn{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    
    }
    
    
    
    /* For Tablet View */
    @media screen and (min-device-width: 768px)
    and (max-device-width: 1024px) {
     
        .error{
            font-weight: 600;
            font-size: 100px;
            line-height: 20px;
            }
            
            .notfound{
            font-weight: 600;
            font-size: 30px;
            line-height: 20px;
            }
            
            .txt
            {
            font-size: 16px;
            line-height: 20px;
            }
    }
    
    
    /* For Mobile View */
    @media screen and (max-device-width: 767px)
     {
        .error{
            font-weight: 600;
            font-size: 70px;
            line-height: 20px;
            }
            
            .notfound{
            font-weight: 600;
            font-size: 25px;
            line-height: 20px;
            }
            
            .txt
            {
            font-size: 14px;
            line-height: 20px;
            }
    }