.footer{

    padding: 2%;
    background-color: #3A3A3A;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  .heading-text{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 53px;    
    color: #FFFFFF;
    
  }
  .sub-text{
    font-family:'Poppins', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 28px;

    
    letter-spacing: 0.02em;
    
    color: rgba(246, 247, 252, 0.7);
    
  }
  .heading-text2{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #FFFFFF;
    
  }
  .sub-text > li > a {
    text-decoration: none !important;
    color: inherit !important;
  }
  .list{
      list-style-type: none;
      padding-left:0%;
      cursor: pointer;
  }

  
  .footdiv{
    background-color: #FFFFFF !important;
    height: 130px;
    width: 225px;
    box-shadow: 0px 4px 12px rgba(255, 255, 255, 0.12);
    border-radius: 16px;
    margin-top: 5px;
  }
  .icon:hover{
    /* filter: invert(21%) sepia(33%) saturate(946%) hue-rotate(207deg) brightness(89%) contrast(97%); */
    filter: invert(58%) sepia(14%) saturate(682%) hue-rotate(222deg) brightness(79%) contrast(89%);
  }
  .footlogo{
    height: 38% !important;
  }
 
  .icon_linksfoot
  {
      margin: 8px 8px;
      margin-top: 0 !important;
  }

  @media screen and (max-width:768px){
    .footdiv{
      margin-bottom: 20px !important;
    }
  }
  @media screen and (max-width:576px){
    .footdiv{
      display:block !important;
      margin-left: auto;
      margin-right: auto;
      height: 130px;
      width: 280px;
    }
    .footlogo{
      height: 38% !important;
    }
  }