.title-text{
    font-family: 'Poppins',sans-serif;
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 20px;
letter-spacing: 0.01em;
color: #000000;
}
.card-text {
    font-family: 'Poppins',sans-serif;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #000000;
}
.customcard{
    position: absolute;
    box-shadow: 0 0.3rem 0.5rem rgb(0 0 0 / 13%) !important;
/* min-height: 400px !important; */

}