.heroText{
    position: absolute;
  top: 78%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #FFFFFF;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  font-family: 'Poppins',sans-serif;
font-style: normal;
font-size: 1.5vw;
/* identical to box height, or 133% */

text-align: center;
letter-spacing: 0.01em;

color: #023B59;
}
