.skeleton {
    background-color: #ddd;
    margin: 10px 0;
    border-radius: 4px;
}

.skeleton.text{
    width: 100%;
    height: 12px;
}

.skeleton.title{
    width: 50%;
    height:20px;
    margin-bottom: 13px;
    display: flex;
    justify-content: center;
}

.skeleton.avatar{
    width:80px;
    height:80px;
    border-radius: 50%;
    margin-bottom: 15px;
}


.skeleton-card{
    margin: 20px auto;
    min-height: '300px';
    max-height: '100%';
    padding: '10%';
    border-radius: '16px';
    left:0;
    right: 0;
    /* color: #c8c4c4 */
}
.customskelcard{
    position: relative;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05) !important;
    overflow: hidden !important;
    overflow-y: hidden;
/* min-height: 400px !important; */

}

.shimmer-wrapper{
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height: 100%;
    animation: loading 1.0s linear infinite alternate;
}
.shimmer{
    width: 50%;
    height: 100%;
    background: rgba(255,255,255,0.3);
    transform: skewX(-20deg);
    box-shadow: 0 0 30px 30px rgba(255,255,255,0.05);

}

@keyframes loading {
    0% {transform: translateX(-150%)}
    50% {transform: translateX(-60%)}
    100% {transform: translateX(150%)}
    
}